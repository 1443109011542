.swiperjs {
  max-width: 124rem;
  padding: 1rem 1rem;
  margin: 0 auto;

}

.swiper_container {
  padding: 2rem 0 !important;
  position: relative;
  /* height: 85vh; */
}

.swiper-slide {
  display:flex !important;
  background: transparent !important;
  align-items: center !important;
  justify-content:center !important;
  
}

@media (max-width: 500px) {
  .swiper_container {
    /* height: 47rem; */
  }
  .swiper-slide {
    /* width: 28rem !important; */
    /* height: 36rem !important; */
    /* display: flex !important; */
    /* margin: 0 !important; */
    /* justify-content: center !important; */
  }
  .swiper-slide img {
    /* width: 28rem !important; */
    height: 36rem !important;
  }
}

.swiper-slide img {
  /* width: 37rem; */
  /* height: 42rem; */
  /* border-radius: 2rem; */
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}
  .slider-arrow{
   color: rgba(236, 104, 28, 1) !important;
  }
/* .slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: red;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: red;
} */