/* You can add global styles to this file, and also import other style files */
:root {
  /* Primary Colors */
  --o-100: #FFE0CC;
  --o-200: #FFC299;
  --o-300: #FFA366;
  --o-400: #FF8533;
  --o-500: #FF6600;
  --o-600: #CC5200;
  --o-700: #B34700;
  --o-800: #803300;
  --o-900: #4C1F00;

  /* Secondary Colors */
  --b-100: #D9E5EE;
  --b-200: #B2CBDE;
  --b-300: #8CB0CD;
  --b-400: #6596BD;
  --b-500: #3F7CAC;
  --b-600: #32638A;
  --b-700: #264A67;
  --b-800: #193245;
  --b-900: #0D1922;

  /* Tertiary Colors */
  --g-100: #DDDDDC;
  --g-200: #BBBBBA;
  --g-300: #9A9897;
  --g-400: #787675;
  --g-500: #565452;
  --g-600: #454342;
  --g-700: #343231;
  --g-800: #222221;
  --g-900: #111110;

  /* MARGINS */
  --margin-large: 13.31rem;
  --margin-medium: 9.9rem;
  --margin-small: 5rem;
}

.slick-slider{
  max-width: 100vw;
  width: 100%;
  overflow: hidden !important;
  }

.swal2-container{
  z-index: 2000 !important;
}