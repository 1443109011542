.react-autosuggest__container{
    display: flex;
    
  }
  .react-autosuggest__input{
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    width: 100%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    
  }
  .react-autosuggest__input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .react-autosuggest__suggestions-container {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 45px;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  
  .react-autosuggest__suggestions-container--open{
    display: flex;
  }
  
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    padding: 10px;
    cursor: pointer;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ccc;
  }
  