.banner-container {
    height: 400px;
  }

  ._carousel_container img {
    height: 400px;
    width: 100%;
    object-fit: cover
  }

.hero_text_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}